import React, {Component} from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import Pagination from "../elements/common/Pagination";
import BlogList from "../elements/blog/BlogList";
import ScrollToTop from 'react-scroll-up';
import {FiChevronUp} from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import DocumentMeta from 'react-document-meta';

const constant = require('../../config/Constant');

class Clients extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: true,
      clientsLogo: [],
      clientHeader: '',
      clientsMeta: null
    };
  }

  componentDidMount() {
    fetch(constant.api.url.API_URL + '/api/brand-block/11?_format=json', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(res => res.json())
      .then((responseJson) => {
          this.setState({
            isLoaded: false,
            clientsLogo: responseJson
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )

    fetch(constant.api.url.API_URL + '/block/image-callout/23?_format=json', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(res => res.json())
      .then((responseJson) => {
          this.setState({
            isLoaded: false,
            clientHeader: responseJson
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )


    fetch(constant.api.url.API_URL + '/clients?_format=json', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(res => res.json())
      .then((responseJson) => {
          this.setState({
            isLoaded: false,
            clientsMeta: responseJson.metatag ? responseJson.metatag : null,
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          })
        })
  }

  render() {
    let clientsLogo = this.state.clientsLogo;
    let clientHeader = this.state.clientHeader;
    let title = 'Clients';
    if (clientHeader !== "") {
      title = clientHeader[0].field_title;
    }

    let clientsMeta = this.state.clientsMeta;

    let meta = '';
    if (clientsMeta !== null) {
      meta = {
        title: clientsMeta.value.title ? clientsMeta.value.title : null,
        description: clientsMeta.value.description ? clientsMeta.value.description : null,
        meta: {
          charset: 'utf-8',
          name: {
            keywords: clientsMeta.value.keywords ? clientsMeta.value.keywords : null,
          }
        }
      };
    }

    return (
      <React.Fragment>
        <DocumentMeta {...meta} />
        <PageHelmet pageTitle='Clients Logo'/>

        <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png"/>
        {/* Start Breadcrump Area */}
        <Breadcrumb title={title} serviceBanner={clientHeader}/>
        {/* End Breadcrump Area */}


        {/* Start Blog Area */}
        <div class="rn-brand-area ptb--120 bg_color--5">
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                {clientsLogo.length > 0 ?
                  <ul class="brand-list branstyle--2">
                    {clientsLogo.map((logos, index) => (
                      <li>
                        <a target={logos.field_target} href={logos.field_brand_link}>
                          <img src={constant.api.url.API_URL + logos.field_image} alt="Logo Images"/>
                        </a>
                      </li>
                    ))}
                  </ul>
                  : ""}
              </div>
            </div>
          </div>
        </div>

        {/* End Blog Area */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp/>
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer/>

      </React.Fragment>
    )
  }
}

export default Clients;

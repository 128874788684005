import React, {Component} from "react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faStroopwafel} from '@fortawesome/free-solid-svg-icons';
import {FiCast, FiLayers, FiUsers, FiMonitor} from "react-icons/fi";
// import { FontAwesomeIcon } from '../../__mocks__/@fortawesome/react-fontawesome';
import {Link} from 'react-router-dom';

const constant = require('../../../config/Constant');

const ServiceList = []

class ServiceTwo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: true,
      services: ServiceList,
      serviceBlock: ''
    };
  }

  componentDidMount() {
    fetch(constant.api.url.API_URL + '/api/services?_format=json', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(res => res.json())
      .then((responseJson) => {
          this.setState({
            isLoaded: false,
            services: responseJson
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
    this.getServiceBlock();
  }

  getServiceBlock() {
    fetch(constant.api.url.API_URL + '/block/10?_format=json', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(res => res.json())
      .then((responseJson) => {
          this.setState({
            isLoaded: false,
            serviceBlock: responseJson
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }

  render() {
    let ServiceList = this.state.services.slice(0, 4);
    let serviceBlock = this.state.serviceBlock;
    return (
      <React.Fragment>
        {ServiceList.length > 0 && serviceBlock.field_show_block && serviceBlock.field_show_block[0].value ?
          <div className="service-area ptb--80  bg_image bg_image--3">
            <div className="container">
              <div className="row">
                {serviceBlock !== "" ?
                  <div className="col-lg-4 col-12">
                    <div className="section-title mt--30 mt_md--5 mt_mobile--5 mb_mobile--10">
                      <h2 className="title">{serviceBlock.field_title[0].value}</h2>
                      {serviceBlock.body.length > 0 ?
                        <p dangerouslySetInnerHTML={{__html: serviceBlock.body[0].value}}></p>
                        : ""}

                      {serviceBlock.field_call_to_action.length > 0 ?
                        <div className="service-btn">
                          <a className="btn-transparent rn-btn-dark"
                             href={serviceBlock.field_call_to_action[0].uri.replace("internal:", "")}><span
                            className="text">{serviceBlock.field_call_to_action[0].title}</span></a>
                        </div>
                        : ""}
                    </div>
                  </div>
                  : ""}
                <div className="col-lg-8 col-12 mt_md--50">
                  <div className="row service-one-wrapper">

                    {ServiceList.map((val, i) => (
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12" key={i}>
                        <Link to={val.view_node}>
                          <div className="service service__style--2">
                            <div className="icon">
                              <i className={"fa " + val.field_icon}></i>
                            </div>
                            <div className="content">
                              <h3 className="title">{val.title}</h3>
                              <p dangerouslySetInnerHTML={{__html: val.field_teaser}}></p>
                            </div>
                          </div>
                        </Link>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          : ""}
      </React.Fragment>
    )
  }
}

export default ServiceTwo;

import React, {Component} from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import CounterOne from "../elements/counters/CounterOne";
import Testimonial from "../elements/Testimonial";
import BrandTwo from "../elements/BrandTwo";
import {FaFacebookF, FaLinkedinIn, FaTwitter} from "react-icons/fa";
import ScrollToTop from 'react-scroll-up';
import {FiChevronUp} from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import DocumentMeta from 'react-document-meta';

const constant = require('../../config/Constant');


class About extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: true,
      title: 'About',
      description: '',
      image: '',
      image_alt: '',
      image_title: '',
      team: [],
      fun_fact: [],
      findWork: '',
      aboutHeader: '',
      aboutContent: null,
      facFactsText: '',
      teamText: null
    };
  }

  componentDidMount() {
    fetch(constant.api.url.API_URL + '/about?_format=json', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(res => res.json())
      .then((responseJson) => {
          this.setState({
            isLoaded: false,
            title: responseJson.title[0].value,
            description: responseJson.body[0].value,
            image: responseJson.field_image[0].url ? responseJson.field_image[0].url : null,
            image_alt: responseJson.field_image[0].alt ? responseJson.field_image[0].alt : null,
            image_title: responseJson.field_image[0].title ? responseJson.field_image[0].title : null,
            aboutContent: responseJson.metatag ? responseJson.metatag : null,
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )

    fetch(constant.api.url.API_URL + '/api/team?_format=json', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(res => res.json())
      .then((responseJson) => {
          this.setState({
            team: responseJson,

          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )

    fetch(constant.api.url.API_URL + '/block/16?_format=json', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(res => res.json())
      .then((responseJson) => {
          this.setState({
            findWork: responseJson,

          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )

    fetch(constant.api.url.API_URL + '/block/image-callout/24?_format=json', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(res => res.json())
      .then((responseJson) => {
          this.setState({
            isLoaded: false,
            aboutHeader: responseJson
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )

    fetch(constant.api.url.API_URL + '/block/29?_format=json', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(res => res.json())
      .then((responseJson) => {
          this.setState({
            isLoaded: false,
            facFactsText: responseJson
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          })
        });

      fetch(constant.api.url.API_URL+'/block/36?_format=json', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(res => res.json())
      .then((responseJson) => {
          this.setState({
            isLoaded: false,
            teamText: responseJson
          });
        },
        (error) => {
          this.setState({
            isLoaded: false,
            error
          })
        })
  }

  render() {
    let title = this.state.title,
      description = this.state.description;
    let team = this.state.team;
    let fun_fact = this.state.fun_fact;
    let find_work = this.state.findWork;
    let aboutHeader = this.state.aboutHeader;
    let aboutContent = this.state.aboutContent;
    let teamText = this.state.teamText;
    let about_title = title;
    if (aboutHeader !== "") {
      about_title = aboutHeader[0].field_title;
    }
    let teamTitle='',teamDescription='';
    if( teamText ){
      teamTitle = teamText.info[0].value;
      teamDescription = teamText.body[0].value;
    }

    let meta = '';
    if (aboutContent !== null) {
      meta = {
        title: aboutContent.value.title ? aboutContent.value.title : null,
        description: aboutContent.value.description ? aboutContent.value.description : null,
        meta: {
          charset: 'utf-8',
          name: {
            keywords: aboutContent.value.keywords ? aboutContent.value.keywords : null,
          }
        }
      };
    }

    const facFactsText = this.state.facFactsText;
    return (
      <React.Fragment>
        <DocumentMeta {...meta} />
        <PageHelmet pageTitle='About'/>
        <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png"/>
        {/* Start Breadcrump Area */}
        <Breadcrumb title={about_title} serviceBanner={aboutHeader}/>
        {/* End Breadcrump Area */}

        {/* Start About Area  */}
        <div className="rn-about-area ptb--120 bg_color--1">
          <div className="rn-about-wrapper">
            <div className="container">
              <div className="row row--35 align-items-center">
                <div className="col-lg-5">
                  <div className="thumbnail">
                    <img className="w-100"
                         src={this.state.image}
                         alt={this.state.image_alt}
                         title={this.state.image_title}
                    />
                  </div>
                </div>
                <div className="col-lg-7">
                  <div className="about-inner inner">
                    <div className="section-title">
                      <h2 className="title">{title}</h2>
                      <p className="description"
                         dangerouslySetInnerHTML={{
                           __html: description
                         }}></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End About Area  */}

        {/* Start CounterUp Area */}
        <CounterOne fun_fact="fun_fact" title={facFactsText}/>
        {/* End CounterUp Area */}

        {/* Start Finding Us Area  */}
        {find_work !== "" ?
          <div className="rn-finding-us-area rn-finding-us bg_color--1">
            <div className="inner">
              <div className="content-wrapper">
                <div className="content">
                  <h4 className="theme-gradient">{find_work.field_title[0].value}</h4>
                  <p dangerouslySetInnerHTML={{__html: find_work.body[0].value}}></p>
                  {find_work.field_call_to_action !== "" ?
                    <a className="rn-btn btn-white"
                       href={find_work.field_call_to_action[0].uri.replace("internal:", "")}>{find_work.field_call_to_action[0].title}</a>
                    : ""}
                </div>
              </div>
              <div className="thumbnail">
                <div className="image">
                  <img src={find_work.field_image[0].url} alt={find_work.field_image[0].alt}/>
                </div>
              </div>
            </div>
          </div>
          : ""}
        {/* End Finding Us Area  */}

        {/* Start Team Area  */}
        {team.length > 0 ?
          <div className="rn-team-area bg_color--1 ptb--120">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="section-title service-style--3 text-center mb--25">
                    <h2 className="title">{teamTitle}</h2>
                    <p dangerouslySetInnerHTML={{ __html: teamDescription }}></p>
                  </div>
                </div>
              </div>
              <div className="row">
                {team.map((member, index) => (
                  <div className="col-lg-4 col-md-6 col-sm-6 col-12 ">
                    <div className="team">
                      <div className="thumbnail">
                        <img className="w-100" src={constant.api.url.API_URL + member.field_image} alt="Blog Images"/>
                      </div>
                      <div className="content">
                        <h4 className="title">{member.title}</h4>
                        <p className="designation"><span
                          dangerouslySetInnerHTML={{__html: member.field_designation}}></span></p>
                      </div>
                      <ul className="social-icon">
                        {member.field_facebook !== "" ?
                          <li><a href={member.field_facebook}><FaFacebookF/></a></li>
                          : ""}
                        {member.field_linkedin !== "" ?
                          <li><a href={member.field_linkedin}><FaLinkedinIn/></a></li>
                          : ""}
                        {member.field_twitter !== "" ?
                          <li><a href={member.field_twitter}><FaTwitter/></a></li>
                          : ""}
                      </ul>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          : ''}
        {/* End Team Area  */}
        {/* Start Testimonial Area */}
        <Testimonial/>
        {/* End Testimonial Area */}
        {/* Start Brand Area */}
        <BrandTwo/>
        {/* End Brand Area */}
        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp/>
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer/>
      </React.Fragment>
    )
  }
}

export default About
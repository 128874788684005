import React, {Component, Fragment} from "react";
import Slider from "react-slick";
import {Link} from 'react-router-dom';
import {portfolioSlick2} from "../../../page-demo/script";

const constant = require('../../../../config/Constant');

const PortfolioList = []

class Portfolio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: true,
      items: PortfolioList,
      portfolioText: '',
    };
  }

  componentDidMount() {
    fetch(constant.api.url.API_URL + '/portfolio?_format=json', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(res => res.json())
      .then((responseJson) => {
          this.setState({
            isLoaded: false,
            items: responseJson
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )

    fetch(constant.api.url.API_URL + '/block/28?_format=json', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(res => res.json())
      .then((responseJson) => {
          this.setState({
            isLoaded: false,
            portfolioText: responseJson
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          })
        })
  }

  render() {
    const portfolioText = this.state.portfolioText;
    let title = 'Our Works',
      description = 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.';
    if (portfolioText.info) {
      title = portfolioText.info[0].value;
    }
    if (portfolioText.body) {
      description = portfolioText.body[0].value;
    }
    let PortfolioList = this.state.items;
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };
    return (
      <React.Fragment>
        {portfolioText.field_show_block && portfolioText.field_show_block[0].value ?
          <div className="portfolio-area ptb--120 bg_color--1">
            <div className="portfolio-sacousel-inner mb--55">
              <div className="portfolio-wrapper">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="section-title">
                        <h2>{title}</h2>
                        <p dangerouslySetInnerHTML={{__html: description}}></p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="portfolio-slick-activation mt--70 mt_sm--40">
                  <Slider {...portfolioSlick2}>
                    {PortfolioList.map((value, index) => (
                      <div className="portfolio" key={index}>
                        <div className="thumbnail-inner">
                          <div className={`thumbnail `}
                               style={{backgroundImage: `url(${constant.api.url.API_URL}${value.field_po_thumbnail})`}}></div>
                          <div className={`bg-blr-image ${value.image}`}></div>
                        </div>
                        <div className="content">
                          <div className="inner">
                            <p>{value.category}</p>
                            <h4><Link to={value.path}><span
                              dangerouslySetInnerHTML={{__html: value.title}}></span></Link></h4>
                            <div className="portfolio-button">
                              <Link className="rn-btn" to={value.path}>Case Study</Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </Slider>
                </div>
              </div>
            </div>
          </div>
          : ""}
      </React.Fragment>
    )
  }
}

export default Portfolio;
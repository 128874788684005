import React, {Component, Fragment} from "react";
import {Link} from 'react-router-dom'
import BlogContent from "./BlogContent";
import ReactPaginate from 'react-paginate';

const constant = require('../../../config/Constant');


class BLogList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: true,
      items: [], //BlogContent.slice(0 , 6),
      pager: '',
    };
    this.getPortResourceList = this.getPortResourceList.bind(this);
  }

  componentDidMount() {
    fetch(constant.api.url.API_URL + '/api/resources?_format=json', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(res => res.json())
      .then((responseJson) => {
          this.setState({
            isLoaded: false,
            items: responseJson.results,
            pager: responseJson.pager
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          })
        })
  }

  async getPortResourceList(data) {
    var arr = this.state.items;
    fetch(constant.api.url.API_URL + '/api/resources?_format=json&page=' + data.selected, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(res => res.json())
      .then((responseJson) => {
          var allItems = arr.concat(responseJson.results);
          this.setState({
            isLoaded: false,
            items: responseJson.results
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }

  render() {
    let PostList = this.state.items;
    let pager = this.state.pager;
    console.log(pager);
    return (
      <Fragment>

        {(PostList !== "" && PostList.length > 0) ?
          <div className="row">
            {PostList.map((value, i) => (
              <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                <div className="blog blog-style--1">
                  <div className="thumbnail">
                    <Link to={ value.path}>
                      <img className="w-100" src={constant.api.url.API_URL + value.field_news_thumbnail}
                           alt="Blog Images"/>
                    </Link>
                  </div>
                  <div className="content">
                    <p className="blogtype">{value.field_news_category}</p>
                    <h4 className="title"><Link to={value.path}>{value.title}</Link></h4>
                    <div className="blog-btn">
                      <a className="rn-btn text-white" href={value.path}>Read More</a>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          : ""}
        <div className="rn-pagination text-center">
          <ReactPaginate
            previousLabel={'Previous'}
            nextLabel={'Next'}
            breakLabel={'...'}
            breakClassName={'break-me'}
            pageCount={pager.pages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={this.getPortResourceList}
            containerClassName={'page-list'}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
          />
        </div>
      </Fragment>
    );
  }
}

export default BLogList;
import React, {Component} from "react";
import PageHelmet from "../component/common/Helmet";
import {FiHeadphones, FiMail, FiMapPin} from "react-icons/fi";
import GoogleMapReact from 'google-map-react';
import ContactTwo from "../elements/contact/ContactTwo";
import BrandTwo from "../elements/BrandTwo";
import ScrollToTop from 'react-scroll-up';
import {FiChevronUp} from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import DocumentMeta from 'react-document-meta';

const constant = require('../../config/Constant');

const AnyReactComponent = ({text}) => <div>{text}</div>;

class Contact extends Component {
  static defaultProps = {
    center: {
      lat: 59.95,
      lng: 30.33
    },
    zoom: 11
  };

  constructor(props) {
    super(props);
    this.state = {
      contactInfo: '',
      contactBanner: '',
      contactMeta: null
    }
  }

  componentDidMount() {
    fetch(constant.api.url.API_URL + '/block/5?_format=json', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(res => res.json())
      .then((responseJson) => {
          this.setState({
            isLoaded: false,
            contactInfo: responseJson

          });
        },
        (error) => {
          this.setState({
            error
          });
        }
      )

    fetch(constant.api.url.API_URL + '/contact-0?_format=json', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(res => res.json())
      .then((responseJson) => {
          this.setState({
            isLoaded: false,
            contactMeta: responseJson.metatag ? responseJson.metatag : null,
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          })
        })

    this.getContactBanner();
  }

  getContactBanner() {
    fetch(constant.api.url.API_URL + '/block/18?_format=json', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(res => res.json())
      .then((responseJson) => {
          this.setState({
            isLoaded: false,
            contactBanner: responseJson

          });
        },
        (error) => {
          this.setState({
            error
          });
        }
      )
  }


  render() {
    let contactInfo = this.state.contactInfo;
    let contactBanner = this.state.contactBanner;

    let contactMeta = this.state.contactMeta;
    let meta = '';
    if (contactMeta !== null) {
      meta = {
        title: contactMeta.value.title ? contactMeta.value.title : null,
        description: contactMeta.value.description ? contactMeta.value.description : null,
        meta: {
          charset: 'utf-8',
          name: {
            keywords: contactMeta.value.keywords ? contactMeta.value.keywords : null,
          }
        }
      };
    }
    let divBgImage = {};
    if (contactBanner !== "" && contactBanner.field_image[0].url !== '') {
      divBgImage = {
        backgroundColor: "#343a40",
        backgroundImage: "url(" + contactBanner.field_image[0].url + ")"
      };
    }
    let contactDesc = 'Your Total Access Guide To Success';
    if (contactBanner && contactBanner.body.length > 0 && contactBanner.body[0].value !== "" ) {
       contactDesc = contactBanner.body[0].value;
    }
    return (
      <React.Fragment>
        <DocumentMeta {...meta} />
        <PageHelmet pageTitle='Contact'/>

        <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png"/>

        {/* Start Breadcrump Area */}
        <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--1" style={divBgImage} data-black-overlay="6">

            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="rn-page-title text-center pt--100">
                    <h2 className="title theme-gradient">
                    {contactBanner && contactBanner.field_title.length > 0 && contactBanner.field_title[0].value !== "" ?
                      contactBanner.field_title[0].value
                      : "Connect With Us"}
                    </h2>
                    <p dangerouslySetInnerHTML={{__html: contactDesc}}></p>

                  </div>
                </div>
              </div>
            </div>
          </div>
        {/* End Breadcrump Area */}


        {/* Start Contact Top Area  */}
        {contactInfo !== '' ?
          <>
            <div className="rn-contact-top-area ptb--120 bg_color--5">
              <div className="container">

                <div className="row">
                  {/* Start Single Address  */}
                  {contactInfo.field_phone.length > 0 ?
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                      <div className="rn-address">
                        <div className="icon">
                          {contactInfo.field_contact_label_icon.length > 0 ?
                            <i class={"fa " + contactInfo.field_contact_label_icon[0].value} aria-hidden="true"></i>
                            : ""}
                        </div>
                        <div className="inner">
                          {contactInfo.field_contact_label.length > 0 ?
                            <h4 className="title">{contactInfo.field_contact_label[0].value}</h4>
                            : ""}
                          {contactInfo.field_phone.map((phone, index) => (
                            <p><a href={'tel:' + phone.value}>{phone.value}</a></p>
                          ))}
                        </div>
                      </div>
                    </div>
                    : ""}
                  {/* End Single Address  */}

                  {/* Start Single Address  */}
                  {contactInfo.field_email.length > 0 ?
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_mobile--50">
                      <div className="rn-address">
                        <div className="icon">
                          {contactInfo.field_email_label_icon.length > 0 ?
                            <i class={"fa " + contactInfo.field_email_label_icon[0].value} aria-hidden="true"></i>
                            : ""}
                        </div>
                        <div className="inner">
                          {contactInfo.field_email_label.length > 0 ?
                            <h4 className="title">{contactInfo.field_email_label[0].value}</h4>
                            : ""}
                          {contactInfo.field_email.map((email, index) => (
                            <p><a href={'mailto:' + email.value}>{email.value}</a></p>
                          ))}
                        </div>
                      </div>
                    </div>
                    : ""}
                  {/* End Single Address  */}

                  {/* Start Single Address  */}
                  {contactInfo.field_location.length > 0 ?
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_md--50 mt_sm--50">
                      <div className="rn-address">
                        <div className="icon">
                          {contactInfo.field_location_label_icon.length > 0 ?
                            <i class={"fa " + contactInfo.field_location_label_icon[0].value} aria-hidden="true"></i>
                            : ""}
                        </div>
                        <div className="inner">
                          {contactInfo.field_location_label.length > 0 ?
                            <h4 className="title">{contactInfo.field_location_label[0].value}</h4>
                            : ""}
                          {contactInfo.field_location.map((location, index) => (
                            <div dangerouslySetInnerHTML={{__html: location.value}}></div>

                          ))}
                        </div>
                      </div>
                    </div>
                    : ""}
                  {/* End Single Address  */}

                </div>
              </div>

            </div>


            <div className="rn-contact-page ptb--120 bg_color--1">
              <ContactTwo contactInfo={contactInfo}/>
            </div>
          </>

          : ''}
        {/* End Contact Top Area  */}


        {/* Start Contact Map  */}

        {/* End Contact Map  */}

        {/* Start Brand Area */}

        {/* End Brand Area */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp/>
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer/>

      </React.Fragment>
    )
  }
}

export default Contact
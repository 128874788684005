import React, {Component} from "react";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from 'react-modal-video';
import {FiClock, FiUser, FiMessageCircle, FiHeart} from "react-icons/fi";
import {Link} from 'react-router-dom';
import ScrollToTop from 'react-scroll-up';
import {FiChevronUp} from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import DocumentMeta from 'react-document-meta';

const constant = require('../../config/Constant');

class BlogDetails extends Component {
  constructor() {
    super()
    this.state = {
      isOpen: false,
      blogDetails: '',
      message: '',
      subject: '',
      name: '',
      email: '',
      commentSubmitted: '',
      comments: [],
      blogDetailsMeta: null,
      node_content: null
    }
    this.openModal = this.openModal.bind(this)
  }

  componentDidMount() {
    let node_path = this.props.match.params.id;
    fetch(constant.api.url.API_URL + '/resources/' + node_path + '?_format=json', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(res => res.json())
      .then((responseJson) => {
          this.setState({
            blogDetailsMeta: responseJson.metatag ? responseJson.metatag : null,
            node_content: responseJson,
          });
          let node_id = responseJson ? responseJson.nid[0].value : '';
          fetch(constant.api.url.API_URL + '/api/blog-details/' + node_id + '?_format=json', {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json'
            }
          })
            .then(res => res.json())
            .then((responseJson) => {
                this.setState({
                  isLoaded: false,
                  blogDetails: responseJson
                });
              },
              (error) => {
                this.setState({
                  isLoaded: true,
                  error
                });
              }
            );
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      );
    window.scrollTo(0, 0);
    //this.getComments(node_id);
  }

  openModal() {
    this.setState({isOpen: true})
  }

  render() {
    let blogDetails = this.state.blogDetails;
    //var comments = this.state.comments;
    let node_content = this.state.node_content;
    var gallery = "";
    if (blogDetails.length > 0) {
      gallery = blogDetails[0].field_news_gallery.split(", ");
    }

    let blogDetailsMeta = this.state.blogDetailsMeta;
    let meta = '';
    if (blogDetailsMeta !== null) {
      meta = {
        title: blogDetailsMeta.value.title ? blogDetailsMeta.value.title : null,
        description: blogDetailsMeta.value.description ? blogDetailsMeta.value.description : null,
        meta: {
          charset: 'utf-8',
          name: {
            keywords: blogDetailsMeta.value.keywords ? blogDetailsMeta.value.keywords : null,
          }
        }
      };
    }

    return (
      <React.Fragment>
        <DocumentMeta {...meta} />
        <PageHelmet pageTitle={blogDetails ? blogDetails[0].title : ""}/>
        <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png"/>

        {/* Start Breadcrump Area */}
        {blogDetails !== "" ?
          <div>
            <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--1" data-black-overlay="6"
                 style={{backgroundImage: `url(${blogDetails[0].field_image ? constant.api.url.API_URL + blogDetails[0].field_image : '/assets/images/bg/bg-image-1.jpg'})`}}>
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="blog-single-page-title text-center pt--100">
                      <h2 className="title theme-gradient">{blogDetails[0].title}</h2>
                      {/*
                                    <ul className="blog-meta d-flex justify-content-center align-items-center">
                                        <li><FiClock />{ blogDetails[0].created }</li>
                                        <li><FiUser />{ blogDetails[0].uid }</li>
                                        <li><FiMessageCircle />{ blogDetails[0].comment_count } Comments</li>
                                    </ul> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="rn-blog-details pt--110 pb--70 bg_color--1">
              <div className="container">
                <div className="row">
                  {blogDetails[0].body ?
                    <div className="col-lg-12">
                      <div className="inner-wrapper" dangerouslySetInnerHTML={{__html: blogDetails[0].body}}></div>
                    </div>
                    : ""}
                  {node_content.length > 0 && node_content.field_news_multimedia ?
                    <div className="col-lg-12">
                      <div className="mt--10"
                           dangerouslySetInnerHTML={{__html: node_content.field_news_multimedia[0].value}}></div>
                    </div>
                    : ""}
                </div>
              </div>
            </div>
            {gallery.length > 0 ?
              <div className="rn-portfolio-area ptb--120 bg_color--1 line-separator">
                <div className="container">

                  <div class="row">
                    {gallery.map((img, index) => (

                      <div class="col-lg-6">
                        {img.length > 0 ?
                          <div class="item-portfolio-static">
                            <div>
                              <div class="portfolio-static">
                                <div class="thumbnail-inner">
                                  <div class="thumbnail">
                                    <img src={constant.api.url.API_URL + img} alt="Portfolio Images"/>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          : ""}
                      </div>
                    ))}
                  </div>

                </div>
              </div>

              : ""}

          </div>
          : ""}
        {/* End Blog Details */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp/>
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer/>

      </React.Fragment>
    )
  }
}

export default BlogDetails;
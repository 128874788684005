import React, {Component, Fragment} from "react";
import Slider from "react-slick";
import { FaFacebookF , FaLinkedinIn , FaTwitter } from "react-icons/fa";
import { portfolioSlick2 } from "../../../page-demo/script";
import {Link} from "react-router-dom";
const constant = require('../../../../config/Constant');

const TeamList = []

class TeamCarousel extends Component{
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: true,
      items: TeamList,
      teamText: null,
    };
  }
  componentDidMount(){
    fetch(constant.api.url.API_URL+'/api/team?_format=json', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(res => res.json())
      .then((responseJson) => {
          this.setState({
            isLoaded: false,
            items: responseJson

          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )

    fetch(constant.api.url.API_URL+'/block/36?_format=json', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(res => res.json())
      .then((responseJson) => {
          this.setState({
            isLoaded: false,
            teamText: responseJson
          });
        },
        (error) => {
          this.setState({
            isLoaded: false,
            error
          })
        })
  }

  render(){
    let teamText = this.state.teamText;
    console.log(teamText);
    let title = 'Skilled Team',
      description = 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.';
    if( teamText ){
      title = teamText.info[0].value;
      description = teamText.body[0].value;
    }
    let TeamList = this.state.items;
    return(
      <React.Fragment>
        { teamText && teamText.field_show_block[0].value ?
          <div className="portfolio-area ptb--120 bg_color--1">
            <div className="portfolio-sacousel-inner mb--55">
              <div className="portfolio-wrapper">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="section-title">
                        <h2>{title}</h2>
                        <p dangerouslySetInnerHTML={{ __html: description }}></p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="portfolio-slick-activation mt--70 mt_sm--40">
                  <Slider {...portfolioSlick2}>
                    {TeamList.map((team , index) => (
                      <div className="portfolio" key={index}>
                        <div className="thumbnail-inner">
                          <div className={`thumbnail `} style={{backgroundImage:`url(${constant.api.url.API_URL}${team.field_image})`}}></div>
                          <div className={`bg-blr-image ${team.field_image}`}></div>
                        </div>
                        <div className="content">
                          <div className="inner">
                            <h4><Link to={""}><span dangerouslySetInnerHTML={{__html: team.title}}></span></Link></h4>
                            <div className="text-white">
                              <span dangerouslySetInnerHTML={{__html:team.field_designation}}></span>
                            </div>
                          </div>
                        </div>
                        {/*<ul className="social-icon" >
                          {team.field_facebook !== "" ?
                            <li><a href={team.field_facebook}><FaFacebookF /></a></li>
                            : ""}
                          {team.field_linkedin !== "" ?
                            <li><a href={team.field_linkedin}><FaLinkedinIn /></a></li>
                            : ""}
                          {team.field_twitter !== "" ?
                            <li><a href={team.field_twitter}><FaTwitter /></a></li>
                            : "" }
                        </ul>*/}
                      </div>
                    ))}
                  </Slider>
                </div>
              </div>
            </div>
          </div>
          : "" }
      </React.Fragment>
    )
  }
}
export default TeamCarousel;
import React, { Component } from "react";
const constant = require('../../config/Constant');

class BrandTwo extends Component{
     constructor(props){
        super(props);
        this.state = {
            brandImages: []
        };
    }

    componentDidMount(){
        fetch(constant.api.url.API_URL+'/api/brand-block/6?_format=json', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then((responseJson) => {
                this.setState({
                    isLoaded: false,
                    brandImages: responseJson
                });
            },
            (error) => {
                this.setState({
                    error
                });
            }
        )
    }

    render(){
        let brandImages = this.state.brandImages;
        return(
            <React.Fragment>
            {brandImages.length > 0 && brandImages[0].field_show_block == "1" ?
             <div className="rn-brand-area brand-block ptb--120 bg_image--3">
                 <div class="container">
                     <div class="row">
                         <div class="col-lg-6">
                             <div class="section-title">
                             <h2>{ brandImages[0].info }</h2>
                             { brandImages[0].body !== "" ? 
                                <p dangerouslySetInnerHTML={{ __html: brandImages[0].body }}></p>
                            : "" }
                            </div>
                        </div>

                        { brandImages[0].field_call_to_action !== "" ? 
                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                            <div className="blog-btn text-left text-lg-right mt_sm--10 mt_md--10">
                                <a className="btn-transparent rn-btn-dark" href={ brandImages[0].field_call_to_action }><span className="text">{ brandImages[0].field_call_to_action_1 }</span></a>
                            </div>
                        </div>
                        : "" }
                        
                    </div>
                    
                </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <ul className="brand-style-2">
                                    { brandImages.map((image, index) => (
                                       <li>
                                          <a target={ image.field_target } href={ image.field_brand_link }>
                                            <img src={constant.api.url.API_URL+image.field_image} alt="Logo Images"/>
                                          </a>  
                                        </li>
                                     )) }
                                </ul>
                            </div>
                        </div>
                    </div>
                </div> 
              : null }
            </React.Fragment>
        )
    }
}
export default BrandTwo;
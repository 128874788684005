import React, {Component} from "react";
import {Link} from 'react-router-dom';

const constant = require('../../../../config/Constant');

class About extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: true,
      title: '',
      description: '',
      image: '',
      image_alt: '',
      image_title: '',
    };
  }

  componentDidMount() {
    fetch(constant.api.url.API_URL + '/about?_format=json', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(res => res.json())
      .then((responseJson) => {
          this.setState({
            isLoaded: false,
            title: responseJson.field_teaser_title ? responseJson.field_teaser_title[0].value : null,
            description: responseJson.field_about_teaser ? responseJson.field_about_teaser[0].value : null,
            image: responseJson.field_image[0].url ? responseJson.field_image[0].url : null,
            image_alt: responseJson.field_image[0].alt ? responseJson.field_image[0].alt : null,
            image_title: responseJson.field_image[0].title ? responseJson.field_image[0].title : null
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }

  render() {
    let title = this.state.title,
      description = this.state.description;
    return (
      <React.Fragment>
        <div className="about-wrapper">
          <div className="container">
            <div className="row row--35 align-items-center">

              <div className="col-lg-5 col-md-12">
                <div className="thumbnail">

                  <img className="w-100"
                       src={this.state.image}
                       alt={this.state.image_alt}
                       title={this.state.image_title}
                  />
                </div>
              </div>

              <div className="col-lg-7 col-md-12">
                <div className="about-inner inner">
                  <div className="section-title">
                    <h2 className="title">{title}</h2>
                    <p className="description" dangerouslySetInnerHTML={{__html: description}}></p>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default About;
import React, {Component} from "react";
import {FaTwitter, FaInstagram, FaFacebookF, FaLinkedinIn} from "react-icons/fa";

const constant = require('../../../config/Constant');

const SocialShare = [
  {Social: <FaFacebookF/>, link: 'https://www.facebook.com/'},
  {Social: <FaLinkedinIn/>, link: 'https://www.linkedin.com/'},
  {Social: <FaInstagram/>, link: 'https://www.instagram.com/'},
  {Social: <FaTwitter/>, link: 'https://twitter.com/'},
]

class Footer extends Component {
  constructor() {
    super()
    this.state = {
      isOpen: false,
      quickLinks: '',
      contact: '',
      bottomImage: '',
      copyright: '',
    }
  }

  componentDidMount() {
    fetch(constant.api.url.API_URL + '/block/link/33', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(res => res.json())
      .then((responseJson) => {
          this.setState({
            isLoaded: false,
            quickLinks: responseJson

          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )

    fetch(constant.api.url.API_URL + '/block/13?_format=json', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(res => res.json())
      .then((responseJson) => {
          this.setState({
            isLoaded: false,
            contact: responseJson

          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )

    fetch(constant.api.url.API_URL + '/block/14?_format=json', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(res => res.json())
      .then((responseJson) => {
          this.setState({
            isLoaded: false,
            bottomImage: responseJson

          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )

    fetch(constant.api.url.API_URL + '/block/15?_format=json', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache'
      }
    })
      .then(res => res.json())
      .then((responseJson) => {
          this.setState({
            isLoaded: false,
            copyright: responseJson

          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )

  }

  render() {
    let quickLinks = this.state.quickLinks;
    let contact = this.state.contact;
    let bottomImage = this.state.bottomImage;
    let copyright = this.state.copyright;
    return (
      <React.Fragment>
        <footer className="footer-area">
          <div className="footer-wrapper">
            <div className="row align-items-end row--0">
              <div className="col-lg-6">
                <div className="footer-left">
                  {bottomImage !== "" ?
                    <div className="inner">
                      <span>{bottomImage.field_title[0].value}</span>
                      <h2>{bottomImage.field_sub_heading[0].value}</h2>
                      {bottomImage.field_call_to_action !== "" ?
                        <a className="rn-button-style--2"
                           href={bottomImage.field_call_to_action[0].uri.replace("internal:", "")}>
                          <span>{bottomImage.field_call_to_action[0].title} </span>
                        </a>
                        : ""}
                    </div>
                    : ""}
                </div>
                {bottomImage !== "" && bottomImage.field_image !== "" ?
                  <style
                    dangerouslySetInnerHTML={{__html: `.footer-left .inner::after { content: url('` + bottomImage.field_image[0].url + `') }`}}></style>
                  : ""}
              </div>
              <div className="col-lg-6">
                <div className="footer-right" data-black-overlay="6">
                  <div className="row">
                    {/* Start Single Widget  */}
                    <div className="col-lg-6 col-sm-6 col-12">
                      {quickLinks.length > 0 ?
                        <div className="footer-link">
                          <h4>{quickLinks[0].info}</h4>
                          <ul className="ft-link">
                            {quickLinks.map((link, index) => (
                              <li><a href={link.field_url}>{link.field_label}</a></li>
                            ))}
                          </ul>
                        </div>
                        : ""}
                    </div>
                    {/* End Single Widget
                                        
                                        <div className="col-lg-6 col-sm-6 col-12 mt_mobile--30">
                                            <div className="footer-link">
                                                <h4>Say Hello</h4>
                                                <ul className="ft-link">
                                                    <li><a href="mailto:admin@example.com">admin@example.com</a></li>
                                                    <li><a href="mailto:hr@example.com">hr@example.com</a></li>
                                                </ul>

                                                <div className="social-share-inner">
                                                    <ul className="social-share social-style--2 d-flex justify-content-start liststyle mt--15">
                                                        {SocialShare.map((val , i) => (
                                                            <li key={i}><a href={`${val.link}`}>{val.Social}</a></li>
                                                        ))}
                                                    </ul>
                                                </div> 
                                            </div>
                                        </div>
                                        */}
                    {contact !== "" ?
                      <div className="col-lg-6 col-sm-6 col-12 mt_mobile--30">
                        <div className="footer-link" dangerouslySetInnerHTML={{__html: contact.body[0].value}}>
                        </div>
                      </div>
                      : ""}


                    {/* End Single Widget  */}


                    <div className="col-lg-12">
                      <div className="copyright-text">
                        {copyright !== "" ?
                          <p dangerouslySetInnerHTML={{__html: copyright.body[0].processed}}></p>
                          : ""}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </footer>
      </React.Fragment>
    )
  }
}

export default Footer;
import React, {Component} from "react";
import {Link} from 'react-router-dom';

const constant = require('../../../config/Constant');

const PortfolioListContent = [
  /*{
      image: 'image-1',
      category: 'Development',
      title: 'Getting tickets to the big show'
  },
  {
      image: 'image-2',
      category: 'Development',
      title: 'Getting tickets to the big show'
  },
  {
      image: 'image-3',
      category: 'Development',
      title: 'Getting tickets to the big show'
  },
  {
      image: 'image-4',
      category: 'Development',
      title: 'Getting tickets to the big show'
  },
  {
      image: 'image-3',
      category: 'Development',
      title: 'Getting tickets to the big show'
  },
  {
      image: 'image-4',
      category: 'Development',
      title: 'Getting tickets to the big show'
  }*/
]

class PortfolioList extends Component {
  render() {
    const {column, styevariation} = this.props;
    // const list = this.props.list.slice(0 , this.props.item);
    const list = this.props.list;
    return (
      <React.Fragment>
        {list.map((value, index) => (
          <div className={`${column}`} key={index}>
            <Link to={value.path}>
              <div className={`portfolio ${styevariation}`}>
                <div className="thumbnail-inner">
                  <div className={`thumbnail ${value.field_po_thumbnail}`}
                       style={{backgroundImage: `url(${constant.api.url.API_URL}${value.field_po_thumbnail})`}}></div>
                  <div className={`bg-blr-image ${value.image}`}></div>
                </div>
                <div className="content">
                  <div className="inner">
                    <p>{value.category}</p>
                    <h4><Link to={value.path}><span dangerouslySetInnerHTML={{__html: value.title}}></span></Link></h4>
                    <div className="portfolio-button">
                      <Link className="rn-btn" to={value.path}>View Details</Link>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        ))}
      </React.Fragment>
    )
  }
}

export default PortfolioList;
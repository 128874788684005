import React, {Component} from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import Pagination from "../elements/common/Pagination";
import BlogList from "../elements/blog/BlogList";
import ScrollToTop from 'react-scroll-up';
import {FiChevronUp} from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import DocumentMeta from 'react-document-meta';

const constant = require('../../config/Constant');

class Blog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      blogHeader: '',
      resourcesMeta: null
    }
  }

  componentDidMount() {
    fetch(constant.api.url.API_URL + '/block/image-callout/25?_format=json', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(res => res.json())
      .then((responseJson) => {
          this.setState({
            isLoaded: false,
            blogHeader: responseJson
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )

    fetch(constant.api.url.API_URL + '/resources?_format=json', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(res => res.json())
      .then((responseJson) => {
          this.setState({
            isLoaded: false,
            resourcesMeta: responseJson.metatag ? responseJson.metatag : null,
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          })
        })

  }

  render() {

    let blogHeader = this.state.blogHeader;
    let blog_title = "Resources";
    if (blogHeader !== "") {
      blog_title = blogHeader[0].field_title;
    }
    let resourcesMeta = this.state.resourcesMeta;

    let meta = '';
    if (resourcesMeta !== null) {
      meta = {
        title: resourcesMeta.value.title ? resourcesMeta.value.title : null,
        description: resourcesMeta.value.description ? resourcesMeta.value.description : null,
        meta: {
          charset: 'utf-8',
          name: {
            keywords: resourcesMeta.value.keywords ? resourcesMeta.value.keywords : null,
          }
        }
      };
    }

    return (
      <React.Fragment>
        <DocumentMeta {...meta} />
        <PageHelmet pageTitle='Blog'/>
        <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png"/>
        {/* Start Breadcrump Area */}
        <Breadcrumb title={blog_title} serviceBanner={blogHeader}/>
        {/* End Breadcrump Area */}

        {/* Start Blog Area */}
        <div className="rn-blog-area ptb--120 bg_color--1">
          <div className="container">
            <BlogList/>
          </div>
        </div>
        {/* End Blog Area */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp/>
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer/>

      </React.Fragment>
    )
  }
}

export default Blog;

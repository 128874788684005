'use strict'; // Constants.js

var prod = {
  url: {
    API_URL: 'https://live-the-tag-firm-dev.pantheonsite.io'
  }
};
var dev = {
  url: {
    API_URL: 'https://live-the-tag-firm-dev.pantheonsite.io'
  }
};
export var api = process.env.NODE_ENV === 'development' ? dev : prod;
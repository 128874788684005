import React, {Component, Fragment} from "react";
import ScrollToTop from 'react-scroll-up';
import {FiChevronUp} from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

import SliderOne from "../component/slider/SliderOne";
import ServiceTwo from "../elements/service/ServiceTwo";
import CounterOne from "../elements/counters/CounterOne";
import Testimonial from "../elements/Testimonial";
import TeamCarousel from "../component/HomeLayout/homeOne/TeamCarousel";
import About from "../component/HomeLayout/homeOne/About";
import Portfolio from "../component/HomeLayout/homeOne/Portfolio";
import BlogContent from "../elements/blog/BlogContent";
import BrandTwo from "../elements/BrandTwo";
import Slider from "react-slick";
import {slideSlick} from "../page-demo/script";
import DocumentMeta from 'react-document-meta';

const constant = require('../../config/Constant');


const SlideList = [
  {
    textPosition: 'text-center',
    bgImage: 'bg_image--21',
    category: '',
    title: 'Marketing',
    description: 'There are many variations of passages of Lorem Ipsum available but the majority have suffered alteration.',
    buttonText: 'Contact Us',
    buttonLink: '/contact'
  },
  {
    textPosition: 'text-center',
    bgImage: 'bg_image--23',
    category: '',
    title: 'Development.',
    description: 'There are many variations of passages of Lorem Ipsum available but the majority have suffered alteration.',
    buttonText: 'Contact Us',
    buttonLink: '/contact'
  },
  {
    textPosition: 'text-center',
    bgImage: 'bg_image--22',
    category: '',
    title: 'UX Research.',
    description: 'There are many variations of passages of Lorem Ipsum available but the majority have suffered alteration.',
    buttonText: 'Contact Us',
    buttonLink: '/contact'
  },
  {
    textPosition: 'text-center',
    bgImage: 'bg_image--20',
    category: '',
    title: 'UX Research.',
    description: 'There are many variations of passages of Lorem Ipsum available but the majority have suffered alteration.',
    buttonText: 'Contact Us',
    buttonLink: '/contact'
  }
]

class MainDemo extends Component {

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: true,
      items: [],
      slides: SlideList,
      homepageHeader: '',
      latestNews: '',
      facFactsText: '',
      homeMeta: null
    };
  }

  componentDidMount() {

    fetch(constant.api.url.API_URL + '/home?_format=json', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(res => res.json())
      .then((responseJson) => {
          this.setState({
            isLoaded: false,
            homeMeta: responseJson.metatag ? responseJson.metatag : null,
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          })
        })


    fetch(constant.api.url.API_URL + '/api/resources?_format=json', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(res => res.json())
      .then((responseJson) => {
          this.setState({
            isLoaded: false,
            items: responseJson.results
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          })
        })

    fetch(constant.api.url.API_URL + '/api/slideshow/12?_format=json', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(res => res.json())
      .then((responseJson) => {
          this.setState({
            isLoaded: false,
            slides: responseJson
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          })
        })

    fetch(constant.api.url.API_URL + '/block/services/26', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(res => res.json())
      .then((responseJson) => {
          this.setState({
            isLoaded: false,
            homepageHeader: responseJson
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          })
        })

    fetch(constant.api.url.API_URL + '/block/32?_format=json', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(res => res.json())
      .then((responseJson) => {
          this.setState({
            isLoaded: false,
            latestNews: responseJson
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          })
        })

    fetch(constant.api.url.API_URL + '/block/29?_format=json', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(res => res.json())
      .then((responseJson) => {
          this.setState({
            isLoaded: false,
            facFactsText: responseJson
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          })
        })
  }

  render() {
    const PostList = this.state.items.slice(0, 3);
    const SlideList = this.state.slides;
    const homepageHeader = this.state.homepageHeader;
    const latestNews = this.state.latestNews;
    const facFactsText = this.state.facFactsText;
    let homeMeta = this.state.homeMeta;
    let meta = '';
    if (homeMeta !== null) {
      meta = {
        title: homeMeta.value.title ? homeMeta.value.title : null,
        description: homeMeta.value.description ? homeMeta.value.description : null,
        meta: {
          charset: 'utf-8',
          name: {
            keywords: homeMeta.value.keywords ? homeMeta.value.keywords : null,
          }
        }
      };
    }
    return (

      <Fragment>
        <DocumentMeta {...meta} />
        <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png"/>
        <div className="slider-wrapper">
          <SliderOne homepageHeader={homepageHeader}/>
        </div>
        {/* Start About Area */}
        <div className="about-area about-position-top pb--120">
          <About/>
        </div>
        {/* End About Area */}

        <ServiceTwo/>

        {/* Start Portfolio Area */}
        <Portfolio/>
        {/* End Portfolio Area */}
        {/* Start Team Area */}
        <TeamCarousel/>
        {/* End Team Area */}
        {/* Start CounterUp Area */}
        {facFactsText.field_show_block && facFactsText.field_show_block[0].value ?
          <CounterOne title={facFactsText}/>
          : ""}
        {/* End CounterUp Area */}

        {/* Start Testimonial Area */}
        <Testimonial/>
        {/* End Testimonial Area */}
        {/* Start Blog Area */}
        {PostList.length > 0 && latestNews.field_show_block && latestNews.field_show_block[0].value ?
          <div className="rn-blog-area ptb--120 bg_color--1 mb-dec--30">
            <div className="container">
              {latestNews !== "" ?
                <div className="row align-items-end">
                  <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                    <div className="section-title text-left">
                      <h2>{latestNews.field_title.length > 0 ? latestNews.field_title[0].value : ""}</h2>
                      {latestNews.body.length > 0 && latestNews.body[0].value !== "" ?
                        <p dangerouslySetInnerHTML={{__html: latestNews.body[0].value}}></p>
                        : ""}
                    </div>
                  </div>
                  {latestNews.field_call_to_action.length > 0 && latestNews.field_call_to_action[0].uri !== "" ?
                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                      <div className="blog-btn text-left text-lg-right mt_sm--10 mt_md--10">
                        <a className="btn-transparent rn-btn-dark"
                           href={latestNews.field_call_to_action[0].uri.replace("internal:", "")}><span
                          className="text">{latestNews.field_call_to_action[0].title}</span></a>
                      </div>
                    </div>
                    : ""}
                </div>
                : ""}
              <div className="row mt--60 mt_sm--40">
                {PostList.map((value, i) => (
                  <div className="col-lg-4 col-md-6 col-12" key={i}>
                    <div className="blog blog-style--1">
                      <div className="thumbnail">
                        <a href={value.path}>
                          <img className="w-100" src={constant.api.url.API_URL + value.field_news_thumbnail}
                               alt="Blog Images"/>
                        </a>
                      </div>
                      <div className="content">
                        <p className="blogtype">{value.field_news_category}</p>
                        <h4 className="title"><a href={value.path}>{value.title}</a></h4>
                        <div className="blog-btn">
                          <a className="rn-btn text-white" href={value.path}>Read More</a>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          : ""}
        {/* End Blog Area */}
        {/* Start Brand Area */}
        <BrandTwo/>
        {/* End Brand Area */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp/>
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer/>
      </Fragment>
    )
  }
}

export default MainDemo;
import React, {Component} from "react";
import {Link} from 'react-router-dom';
import {FiX, FiMenu} from "react-icons/fi";

const constant = require('../../../config/Constant');

class Header extends Component {
  constructor(props) {

    super(props);
    this.menuTrigger = this.menuTrigger.bind(this);
    this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
    //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
    window.addEventListener('load', function () {
      console.log('All assets are loaded')
    })
    this.state = {
      error: null,
      isLoaded: true,
      menus: [],
      logo: '',
    };
  }

  menuTrigger() {
    document.querySelector('.header-wrapper').classList.toggle('menu-open')
  }

  CLoseMenuTrigger() {
    document.querySelector('.header-wrapper').classList.remove('menu-open');
  }

  componentDidMount() {
    fetch(constant.api.url.API_URL + '/block/link/30', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(res => res.json())
      .then((responseJson) => {
          this.setState({
            isLoaded: false,
            menus: responseJson
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )

    fetch(constant.api.url.API_URL + '/block/31?_format=json', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(res => res.json())
      .then((responseJson) => {
          this.setState({
            isLoaded: false,
            logo: responseJson
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }


  render() {
    var menus = this.state.menus;
    var site_logo = this.state.logo;
    var elements = document.querySelectorAll('.has-droupdown > a');
    for (var i in elements) {
      if (elements.hasOwnProperty(i)) {
        elements[i].onclick = function () {
          this.parentElement.querySelector('.submenu').classList.toggle("active");
          this.classList.toggle("open");
        }
      }
    }
    const {logo, color = 'default-color'} = this.props;
    let logoUrl;
    // if(logo === 'light'){
    //     logoUrl = <img src="/assets/images/logo/logo-light.png" alt="Digital Agency" />;
    // }else if(logo === 'dark'){
    //     logoUrl = <img src="/assets/images/logo/logo-dark.png" alt="Digital Agency" />;
    // }else if(logo === 'symbol-dark'){
    //     logoUrl = <img src="/assets/images/logo/logo-symbol-dark.png" alt="Digital Agency" />;
    // }else if(logo === 'symbol-light'){
    //     logoUrl = <img src="/assets/images/logo/logo-symbol-light.png" alt="Digital Agency" />;
    // }else{
    //     logoUrl = <img src="/assets/images/logo/TAG-Firm-New-Logo-150x48.png" alt="The TAG Firm" />;
    // }

    if (site_logo.field_image) {
      logoUrl = <img src={site_logo.field_image[0].url} alt={site_logo.field_image[0].alt}/>;
    }

    return (
      <header className={`header-area formobile-menu header--transparent ${color}`}>
        <div className="header-wrapper" id="header-wrapper">
          <div className="header-left">
            <div className="logo">
              <a href="/">
                {logoUrl}
              </a>
            </div>
          </div>
          <div className="header-right">
            <nav className="mainmenunav d-lg-block">
              {/* <li><Link to={ menu.uri }>{ menu.uri }</Link></li> */}
              {menus.length > 0 ?
                <ul className="mainmenu">
                  {menus.map((menu, index) => (

                    <li key={index}><Link to={menu.field_url}>{menu.field_label}</Link></li>

                  ))}
                </ul>
                : ""}

            </nav>
            {/* <div className="header-btn">
                            <a className="rn-btn" href="https://themeforest.net/checkout/from_item/25457315?license=regular">
                                <span>buy now</span>
                            </a>
                        </div> */}
            {/* Start Humberger Menu  */}
            <div className="humberger-menu d-block d-lg-none pl--20">
              <span onClick={this.menuTrigger} className="menutrigger text-white"><FiMenu/></span>
            </div>
            {/* End Humberger Menu  */}
            <div className="close-menu d-block d-lg-none">
              <span onClick={this.CLoseMenuTrigger} className="closeTrigger"><FiX/></span>
            </div>
          </div>
        </div>
      </header>
    )
  }
}

export default Header;
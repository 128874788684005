import React, {Component} from "react";
import PageHelmet from "../component/common/Helmet";
import Slider from "react-slick";
import {slickDot} from "../page-demo/script";
import Breadcrumb from "../elements/common/Breadcrumb";
import PortfolioList from "../elements/portfolio/PortfolioList";
import ScrollToTop from 'react-scroll-up';
import {FiChevronUp} from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import DocumentMeta from 'react-document-meta';

const constant = require('../../config/Constant');

const featured = []
const list = []


class Portfolio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: true,
      items: list,
      featured: featured,
      page: 0,
      serviceBanner: '',
      featuredBlock: '',
      allWork: '',
      portfolioMeta: null

    };
  }

  componentDidMount() {

    fetch(constant.api.url.API_URL + '/portfolio-0?_format=json', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(res => res.json())
      .then((responseJson) => {
          this.setState({
            isLoaded: false,
            portfolioMeta: responseJson.metatag ? responseJson.metatag : null,
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          })
        })


    fetch(constant.api.url.API_URL + '/portfolio/featured?_format=json', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(res => res.json())
      .then((responseJson) => {
          this.setState({
            featured: responseJson
          });
          fetch(constant.api.url.API_URL + '/portfolio?_format=json', {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json'
            }
          })
            .then(res => res.json())
            .then((responseJson) => {
                this.setState({
                  isLoaded: false,
                  items: responseJson
                });
              },
              // Note: it's important to handle errors here
              // instead of a catch() block so that we don't swallow
              // exceptions from actual bugs in components.
              (error) => {
                this.setState({
                  isLoaded: true,
                  error
                });
              }
            )
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )


    this.getServiceBanner();
    this.getFeaturedBlock();
    this.getAllWork();

  }

  async getPortfolioList() {
    await this.setState({
      page: (this.state.page + 1)
    })
    var arr = this.state.items;
    fetch(constant.api.url.API_URL + '/portfolio?_format=json&page=' + this.state.page, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(res => res.json())
      .then((responseJson) => {
          var allItems = arr.concat(responseJson);
          this.setState({
            isLoaded: false,
            items: allItems

          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }

  getServiceBanner() {
    fetch(constant.api.url.API_URL + '/block/image-callout/20?_format=json', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(res => res.json())
      .then((responseJson) => {
          this.setState({
            isLoaded: false,
            serviceBanner: responseJson

          });
        },
        (error) => {
          this.setState({
            error
          });
        }
      )
  }

  getFeaturedBlock() {
    fetch(constant.api.url.API_URL + '/block/21?_format=json', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(res => res.json())
      .then((responseJson) => {
          this.setState({
            isLoaded: false,
            featuredBlock: responseJson

          });
        },
        (error) => {
          this.setState({
            error
          });
        }
      )
  }

  getAllWork() {
    fetch(constant.api.url.API_URL + '/block/22?_format=json', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(res => res.json())
      .then((responseJson) => {
          this.setState({
            isLoaded: false,
            allWork: responseJson

          });
        },
        (error) => {
          this.setState({
            error
          });
        }
      )
  }

  render() {
    let list = this.state.items;
    let featured = this.state.featured;
    let serviceBanner = this.state.serviceBanner;
    let featuredBlock = this.state.featuredBlock;
    let allWork = this.state.allWork;
    let title = "";
    if (serviceBanner !== "") {
      title = serviceBanner[0].field_title;
    }

    let portfolioMeta = this.state.portfolioMeta;

    let meta = '';
    if (portfolioMeta !== null) {
      meta = {
        title: portfolioMeta.value.title ? portfolioMeta.value.title : null,
        description: portfolioMeta.value.description ? portfolioMeta.value.description : null,
        meta: {
          charset: 'utf-8',
          name: {
            keywords: portfolioMeta.value.keywords ? portfolioMeta.value.keywords : null,
          }
        }
      };
    }

    return (
      <React.Fragment>
        <DocumentMeta {...meta} />
        <PageHelmet pageTitle='Portfolio'/>
        <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png"/>
        {/* Start Breadcrump Area */}
        <Breadcrumb title={'Portfolio'} serviceBanner={serviceBanner}/>
        {/* End Breadcrump Area */}

        {/* Start Portfolio Area */}
        {featured.length > 0 ?
          <div className="portfolio-area pt--120 pb--140 bg_color--5">
            <div className="rn-slick-dot">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    {featuredBlock !== "" ?
                      <div className="section-title service-style--3 text-center mb--15">
                        <h2 className="title">{featuredBlock.info[0].value}</h2>
                        {featuredBlock.body.length > 0 ?
                          <p dangerouslySetInnerHTML={{__html: featuredBlock.body[0].value}}></p>
                          : ""}
                      </div>
                      : ""}
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="slick-space-gutter--15 slickdot--20">
                      <Slider {...slickDot}>
                        {featured.map((item, index) => (

                          <div className="portfolio" key={index}>
                            <a className="portfolio-link" href={item.path}>
                              <div className="thumbnail-inner">

                                <div className={`thumbnail `}
                                     style={{backgroundImage: `url(${constant.api.url.API_URL}${item.field_po_thumbnail})`}}></div>
                                <div className={`bg-blr-image`}></div>

                              </div>
                              <div className="content">
                                <div className="inner">

                                  <h4><a href={item.path}><span
                                    dangerouslySetInnerHTML={{__html: item.title}}></span></a>
                                  </h4>
                                  <div className="portfolio-button">
                                    <a className="rn-btn" href={item.path}>Case Study</a>
                                  </div>
                                </div>
                              </div>
                            </a>
                          </div>


                        ))}
                      </Slider>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
          : ""}
        {/* End Portfolio Area */}

        {/* Start Portfolio Area */}

        {list.length > 0 ?
          <div className="portfolio-area ptb--120 bg_color--1">
            <div className="portfolio-sacousel-inner">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="section-title text-center service-style--3 mb--30">
                      {allWork !== "" ?
                        <div className="section-title service-style--3 text-center mb--15">
                          <h2 className="title">{allWork.info[0].value} </h2>
                          {allWork.body.length > 0 ?
                            <p dangerouslySetInnerHTML={{__html: allWork.body[0].value}}></p>
                            : ""}
                        </div>
                        : ""}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <PortfolioList list={list} styevariation="text-left mt--40" column="col-lg-4 col-md-6 col-sm-6 col-12"
                                 item="6"/>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="view-more-btn mt--60 text-center">
                      <a className="rn-button-style--2 btn-solid" onClick={() => this.getPortfolioList()}><span>
                        {allWork.field_view_more_text && allWork.field_view_more_text.length > 0 ? allWork.field_view_more_text[0].value
                        : "View More Projects" }</span></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          : ""}
        {/* End Portfolio Area */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp/>
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer/>

      </React.Fragment>
    )
  }
}

export default Portfolio;
import React, {Component} from "react";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from 'react-modal-video';
import ScrollToTop from 'react-scroll-up';
import {FiChevronUp} from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import DocumentMeta from 'react-document-meta';
const constant = require('../../config/Constant');

class ServiceDetails extends Component {
  constructor() {
    super()
    this.state = {
      isOpen: false,
      item: {},
      title: '',
      description: '',
      image: '',
      serviceDetailsMeta: null,
      teaser: []
    }
    this.openModal = this.openModal.bind(this);
    window.scrollTo(0, 0);
  }

  componentDidMount() {
    let node_id = this.props.match.params.id;
    fetch(constant.api.url.API_URL + '/service/' + node_id + '?_format=json', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(res => res.json())
      .then((responseJson) => {
          this.setState({
            isLoaded: false,
            item: responseJson,
            description: (responseJson.body[0].value) ? responseJson.body[0].value : '',
            image: (responseJson.field_image[0].url) ? responseJson.field_image[0].url : '',
            title: responseJson.title[0].value,
            teaser: responseJson.field_teaser !== undefined ? responseJson.field_teaser : [],
            serviceDetailsMeta: responseJson.metatag ? responseJson.metatag : null,
          })
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      );
    window.scrollTo(0, 0);
  }

  openModal() {
    this.setState({isOpen: true});
  }

  render() {
    let item = this.state;
    console.log('body', item.teaser);
    let meta = '';
    let serviceDetailsMeta = this.state.serviceDetailsMeta;
    if (serviceDetailsMeta !== null) {
      meta = {
        title: serviceDetailsMeta.value.title ? serviceDetailsMeta.value.title : null,
        description: serviceDetailsMeta.value.description ? serviceDetailsMeta.value.description : null,
        meta: {
          charset: 'utf-8',
          name: {
            keywords: serviceDetailsMeta.value.keywords ? serviceDetailsMeta.value.keywords : null,
          }
        }
      };
    }
    let teaser = this.state.teaser;
    return (
      <React.Fragment>
        <DocumentMeta {...meta} />
        {/* Start Pagehelmet  */}
        <PageHelmet pageTitle='Service Details'/>
        {/* End Pagehelmet  */}
        <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png"/>
        {/* Start Breadcrump Area */}
        {(item !== '') ? <>
          <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--1" data-black-overlay="5"
               style={{backgroundImage: `url(${item.image})`}}>
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="rn-page-title text-center pt--100">
                    <h2 className="title theme-gradient">{item.title} </h2>
                    {teaser.length > 0 ?
                      <p dangerouslySetInnerHTML={{__html: teaser[0].value}}></p>
                      : ""}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* End Breadcrump Area */}

          {/* Start Page Wrapper */}
          <div className="rn-service-details ptb--120 bg_color--1">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="service-details-inner">
                    {/* <div className="page-title mb--50">
                                        <h2 className="title">Digital Brands.</h2>
                                        <p>Lorem ipsum dolor sit amet, is. Digital Brands. </p>
                                    </div> */}
                    <div className="inner"
                         dangerouslySetInnerHTML={{__html: item.description}}>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* End Page Wrapper */}

          {/* Start Back To Top */}
          <div className="backto-top">
            <ScrollToTop showUnder={160}>
              <FiChevronUp/>
            </ScrollToTop>
          </div>
          {/* End Back To Top */}
        </> : null}
        <Footer/>

      </React.Fragment>
    )
  }
}

export default ServiceDetails;



import React, {Component} from "react";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from 'react-modal-video';
import {FaTwitter, FaInstagram, FaFacebookF, FaLinkedinIn} from "react-icons/fa";
import ScrollToTop from 'react-scroll-up';
import {FiChevronUp} from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import {FacebookShareButton, LinkedinShareButton, TwitterShareButton} from 'react-share';
import DocumentMeta from 'react-document-meta';
import Slider from "react-slick";
import {portfolioDetailSlick} from "../page-demo/script";
import Gallery from 'react-grid-gallery';

const constant = require('../../config/Constant');

const SocialShare = [
  {type: 'facebook', Social: <FaFacebookF/>, link: 'https://www.facebook.com/'},
  {type: 'linkedin', Social: <FaLinkedinIn/>, link: 'https://www.linkedin.com/'},
  {type: 'instagram', Social: <FaInstagram/>, link: 'https://www.instagram.com/'},
  {type: 'twitter', Social: <FaTwitter/>, link: 'https://twitter.com/'},
]

class PortfolioDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      item: '',
      relatedItems: '',
      node_id: '',
      node_content: '',
      portfolioDetailsMeta: null,
      portfolio_heading: '',
      modalIsOpen: false
    }
    this.openModal = this.openModal.bind(this);


  }

  componentDidMount() {
    let node_path_alias = this.props.match.params.id;
    fetch(constant.api.url.API_URL + '/block/35?_format=json', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(res => res.json())
      .then((responseJson) => {
          this.setState({
            portfolio_heading: responseJson
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      );
    fetch(constant.api.url.API_URL + '/portfolio/' + node_path_alias + '?_format=json', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(res => res.json())
      .then((responseJson) => {
          this.setState({
            isLoaded: false,
            node_content: responseJson,
            portfolioDetailsMeta: responseJson.metatag ? responseJson.metatag : null,
          });

        let node_id = responseJson ? responseJson.nid[0].value : '';

        fetch(constant.api.url.API_URL + '/api/portfolio/detail/' + node_id + '?_format=json', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        })
        .then(res => res.json())
        .then((responseJson) => {
          this.setState({
            isLoaded: false,
            item: responseJson
          });

          let categories_1 = responseJson[0] ? responseJson[0].field_po_categories_1 : '';
          fetch(constant.api.url.API_URL + '/api/portfolio/related-work/' + node_id + '/' + categories_1 + '?_format=json', {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json'
            }
          })
            .then(res => res.json())
            .then((responseJson) => {
                this.setState({
                  isLoaded: false,
                  relatedItems: responseJson,
                  node_id: this.props.match.params.id,

                });
              },
              // Note: it's important to handle errors here
              // instead of a catch() block so that we don't swallow
              // exceptions from actual bugs in components.
              (error) => {
                this.setState({
                  isLoaded: true,
                  error
                });
              }
            );
          },
          (error) => {
            this.setState({
              isLoaded: true,
              error
            });
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      );

    window.scrollTo(0, 0);
  }

  openModal() {
    this.setState({isOpen: true})
  }

  render() {

    let item = this.state.item;
    let itemData = item[0];
    let relatedItems = this.state.relatedItems;
    let node_id = this.state.node_id;
    let post_url = window.location.href;
    let node_content = this.state.node_content;


    let portfolioDetailsMeta = this.state.portfolioDetailsMeta;
    let meta = '';
    if (portfolioDetailsMeta !== null) {
      meta = {
        title: portfolioDetailsMeta.value.title ? portfolioDetailsMeta.value.title : null,
        description: portfolioDetailsMeta.value.description ? portfolioDetailsMeta.value.description : null,
        meta: {
          charset: 'utf-8',
          name: {
            keywords: portfolioDetailsMeta.value.keywords ? portfolioDetailsMeta.value.keywords : null,
          }
        }
      };
    }

    let thumnails = [];
    let images = [];
    if (item && item[0].field_po_gallery != 'undefined') {
      thumnails = item[0].field_po_gallery.split(", ");

      if (item !== "" && item[0].field_po_gallery !== "") {
        thumnails = item[0].field_po_gallery.split(", ");
        for (var i = 0; i < thumnails.length; i++) {
          images.push({
            "src": constant.api.url.API_URL + thumnails[i],
            "thumbnail": constant.api.url.API_URL + thumnails[i],
            thumbnailWidth: 320,
            margin: 10,
          })
        }
      }

    }
    const image = [{src: 'path/to/image-1.jpg'}, {src: 'path/to/image-2.jpg'}];

    let portfolio_heading = this.state.portfolio_heading;
    return (
      <React.Fragment>
        <DocumentMeta {...meta} />
        <PageHelmet pageTitle='Portfolio Details'/>

        <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png"/>


        {/* Start Breadcrump Area */}

        {(item[0]) ? <>
          <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--1" data-black-overlay="6"
               style={{backgroundImage: `url(${node_content.field_image && node_content.field_image.length > 0 ? node_content.field_image[0].url : '/assets/images/bg/bg-image-1.jpg'})`}}>
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="rn-page-title text-center pt--100">
                    <h2 className="title theme-gradient" dangerouslySetInnerHTML={{__html: item[0].title}}></h2>
                    {/*  <p>Contrary to popular belief, Lorem Ipsum is not simply random text. </p> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="rn-portfolio-details ptb--120 bg_color--1">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="portfolio-details">
                    <div className="inner">
                      <h2 dangerouslySetInnerHTML={{__html: item[0].title}}></h2>
                      {node_content !== "" && node_content.body.length > 0 ?
                        <p className="subtitle">
                          <div dangerouslySetInnerHTML={{__html: node_content.body[0].value}}></div>
                        </p>
                        : ""}
                      {node_content && node_content.field_po_multimedia.length > 0 ?
                        <div class="mt--10">
                          <div dangerouslySetInnerHTML={{__html: node_content.field_po_multimedia[0].value}}></div>
                        </div>
                        : ""}
                      <div className="portfolio-view-list d-flex flex-wrap">
                        {item[0].field_po_author_name !== "" ?
                          <div className="port-view">
                            <span>Team</span>
                            <h4>{item[0].field_po_author_name}</h4>
                          </div>
                          : ""}

                        {item[0].field_po_author_company !== "" ?
                          <div className="port-view">
                            <span>Company</span>
                            <h4>{item[0].field_po_author_company}</h4>
                          </div>
                          : ""}

                        { /*
                          <div className="port-view">
                          <span>Date</span>
                          <h4>{item[0].created}</h4>
                      </div> */}
                        {item[0].field_po_button_link !== "" ?
                          <div className="port-view">
                            <span>Website</span>
                            <h4><a class='portfolio-site-link' target="_blank"
                                   href={item[0].field_po_button_link}><span
                              dangerouslySetInnerHTML={{__html: item[0].field_po_button_text}}></span></a></h4>
                          </div>
                          : ""}
                      </div>
                      <div className="categories">
                        <div dangerouslySetInnerHTML={{__html: item[0].field_po_categories}}></div>
                      </div>
                      <div className="portfolio-share-link mt--20 pb--70 pb_sm--40">
                        <ul className="social-share rn-lg-size d-flex justify-content-start liststyle mt--15">
                          <FacebookShareButton url={post_url} children={<li><a><FaFacebookF/></a></li>}/>
                          <LinkedinShareButton url={post_url} children={<li><a><FaLinkedinIn/></a></li>}/>
                          <TwitterShareButton url={post_url} children={<li><a><FaTwitter/></a></li>}/>
                        </ul>
                      </div>
                    </div>
                    <div className="portfolio-thumb-inner">
                      {
                        //  <div className="thumb position-relative mb--30">
                        //     <img src="/assets/images/portfolio/portfolio-big-03.jpg" alt="Portfolio Images"/>
                        //     <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='ZOoVOfieAF8' onClose={() => this.setState({isOpen: false})} />
                        //     <button className="video-popup position-top-center" onClick={this.openModal}><span className="play-icon"></span></button>
                        // </div>
                      }

                      {item.field_po_gallery !== '' ?

                        <div className="row">
                          <div className="col-lg-12">
                            <div className="slick-space-gutter--15 slickdot--20">
                              <Gallery images={images} margin={10}/>
                            </div>
                          </div>
                        </div>
                        : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* End Portfolio Details */}

          {/* Start Related Work */}
          <div className="portfolio-related-work pb--120 bg_color--1">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="section-title text-center">
                    {portfolio_heading !== "" ?
                      <div>
                        {portfolio_heading.field_sub_heading.length > 0 ?
                          <span
                            className="theme-color font--18 fontWeight600">{portfolio_heading.field_sub_heading[0].value}</span>
                          : ""}
                        <h2>{portfolio_heading.field_title[0].value}</h2>
                      </div>

                      : ""}
                  </div>
                </div>
              </div>


              {relatedItems !== "" ?
                <div className="row mt--10">
                  {relatedItems.map((relatedWork, index) => (
                    <div className="col-lg-4 col-md-4 col-12">
                      <div className="related-work text-center mt--30">
                        <div className="thumb">
                          <a href={relatedWork.path}>
                            <img src={constant.api.url.API_URL + relatedWork.field_po_thumbnail}
                                 alt="Portfolio-images"/>
                          </a>
                        </div>
                        <div className="inner">
                          <h4><a href={relatedWork.path}><span
                            dangerouslySetInnerHTML={{__html: relatedWork.title}}></span></a></h4>
                          <div className="categories"
                               dangerouslySetInnerHTML={{__html: relatedWork.field_po_categories}}></div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                : ""}


            </div>
          </div>
          {/* End Related Work */}

          {/* Start Back To Top */}
          <div className="backto-top">
            <ScrollToTop showUnder={160}>
              <FiChevronUp/>
            </ScrollToTop>
          </div>
        </> : null}


        <Footer/>


      </React.Fragment>
    )
  }
}

export default PortfolioDetails;

import React, {Component} from "react";
import * as FontAwesome from 'react-icons/fa';
import PageHelmet from "../component/common/Helmet";
import {Link} from 'react-router-dom';
import Breadcrumb from "../elements/common/Breadcrumb";
import {FiCast, FiLayers, FiUsers, FiMonitor} from "react-icons/fi";
import ScrollToTop from 'react-scroll-up';
import {FiChevronUp} from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import DocumentMeta from 'react-document-meta';

const constant = require('../../config/Constant');

const ServiceList = [
 /* {
    icon: <FiCast/>,
    title: 'Business Stratagy',
    description: 'I throw myself down among the tall grass by the stream as I lie close to the earth.'
  },
  {
    icon: <FiLayers/>,
    title: 'Website Development',
    description: 'I throw myself down among the tall grass by the stream as I lie close to the earth.'
  },
  {
    icon: <FiUsers/>,
    title: 'Marketing & Reporting',
    description: 'I throw myself down among the tall grass by the stream as I lie close to the earth.'
  },
  {
    icon: <FiMonitor/>,
    title: 'Mobile App Development',
    description: 'I throw myself down among the tall grass by the stream as I lie close to the earth.'
  },
  {
    icon: <FiCast/>,
    title: 'Website Development',
    description: 'I throw myself down among the tall grass by the stream as I lie close to the earth.'
  },
  {
    icon: <FiMonitor/>,
    title: 'Marketing & Reporting',
    description: 'I throw myself down among the tall grass by the stream as I lie close to the earth.'
  },*/
]

class Service extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: true,
      services: ServiceList,
      innovation: '',
      costSaving: '',
      specialize: [],
      serviceBlock: '',
      serviceBanner: '',
      serviceMeta: null

    };
  }

  componentDidMount() {
    /*
    fetch(constant.api.url.API_URL + '/services?_format=json', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(res => res.json())
      .then((responseJson) => {
          this.setState({
            isLoaded: false,
            serviceMeta: responseJson.metatag ? responseJson.metatag : null,
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          })
        })
    */

    fetch(constant.api.url.API_URL + '/api/services?_format=json', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(res => res.json())
      .then((responseJson) => {
          this.setState({
            isLoaded: false,
            services: responseJson

          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )

    this.getCostSavingContent();
    this.getInnovativeContent();
    this.getSpecializeContent();
    this.getServiceBlock();
    this.getServiceBanner();
  }

  getInnovativeContent() {
    fetch(constant.api.url.API_URL + '/block/7?_format=json', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(res => res.json())
      .then((responseJson) => {
          this.setState({
            isLoaded: false,
            innovation: responseJson
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }


  getCostSavingContent() {
    fetch(constant.api.url.API_URL + '/block/8?_format=json', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(res => res.json())
      .then((responseJson) => {
          this.setState({
            isLoaded: false,
            costSaving: responseJson
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }

  getSpecializeContent() {
    fetch(constant.api.url.API_URL + '/block/link/34?_format=json', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(res => res.json())
      .then((responseJson) => {
          this.setState({
            isLoaded: false,
            specialize: responseJson
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }

  getServiceBlock() {
    fetch(constant.api.url.API_URL + '/block/10?_format=json', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(res => res.json())
      .then((responseJson) => {
          this.setState({
            isLoaded: false,
            serviceBlock: responseJson
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }

  getServiceBanner() {
    fetch(constant.api.url.API_URL + '/block/image-callout/19?_format=json', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(res => res.json())
      .then((responseJson) => {
          this.setState({
            isLoaded: false,
            serviceBanner: responseJson
          });
        },
        (error) => {
          this.setState({
            error
          });
        }
      )
  }

  render() {
    let ServiceList = this.state.services;
    let costSaving = this.state.costSaving;
    let specialize = this.state.specialize;
    let innovation = this.state.innovation;
    let serviceBlock = this.state.serviceBlock;
    let serviceBanner = this.state.serviceBanner;
    let serviceMeta = this.state.serviceMeta;
    let title = "Services";
    if (serviceBanner !== "") {
      title = serviceBanner[0].field_title;
    }

    let meta = '';
    if (serviceMeta !== null) {
      meta = {
        title: serviceMeta.value.title ? serviceMeta.value.title : null,
        description: serviceMeta.value.description ? serviceMeta.value.description : null,
        meta: {
          charset: 'utf-8',
          name: {
            keywords: serviceMeta.value.keywords ? serviceMeta.value.keywords : null,
          }
        }
      };
    }

    return (
      <React.Fragment>
        <DocumentMeta {...meta} />
        <PageHelmet pageTitle='Service'/>
        <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png"/>

        {/* Start Breadcrump Area */}
        <Breadcrumb title={title} serviceBanner={serviceBanner}/>
        {/* End Breadcrump Area */}
        {innovation !== '' ?
          <div className="service-area ptb--120 bg_color--5">
            <div className="container">
              <div className="row service-one-wrapper">
                <div className="section-title text-center mb--30">
                  {innovation.field_title.length > 0 && innovation.field_title[0].value !== "" ?
                    <h2>{innovation.field_title[0].value}</h2>
                    : ""}
                  {innovation.body.length > 0 && innovation.body[0].value !== "" ?
                    <p dangerouslySetInnerHTML={{__html: innovation.body[0].value}}></p>
                    : ""}

                </div>
                {innovation.field_call_to_action.length > 0 ?
                  <a href={costSaving.field_call_to_action[0].uri}
                     className="service--button">{innovation.field_call_to_action[0].title}</a>
                  : ''}
              </div>
            </div>
          </div>
          : ""}

        {costSaving !== '' ?
          <div className="service-area creative-service-wrapper pt--90 pb--120 bg_color--1">
            <div className="container">
              <div className="row service-one-wrapper">
                <div className="col-lg-12">
                  <div className="section-title text-center mb--30">
                    {costSaving.field_title.length > 0 && costSaving.field_title[0].value !== "" ?
                      <h2>{costSaving.field_title[0].value}</h2>
                      : ""}
                    {costSaving.body.length > 0 && costSaving.body[0].value !== "" ?
                      <p dangerouslySetInnerHTML={{__html: costSaving.body[0].value}}></p>
                      : ""}
                  </div>
                  {costSaving.field_call_to_action.length > 0 ?
                    <a href={costSaving.field_call_to_action[0].uri}
                       className="service--button">{costSaving.field_call_to_action[0].title}</a>
                    : ''}
                </div>

              </div>
            </div>
          </div>
          : ""}

        {/* Start Service Area */}
        <div className="service-area services-block ptb--120 bg_color--5">
          <div className="container">
            <div className="row creative-service">
              {serviceBlock !== "" ?
                <div className="col-lg-12">
                  <div className="section-title text-center mb--30">
                    <h2>{serviceBlock.field_title[0].value}</h2>
                    <p dangerouslySetInnerHTML={{__html: serviceBlock.body[0].value}}></p>
                  </div>
                </div>
                : ""}
            </div>
            <div className="row creative-service-boxes">
              {ServiceList.map((val, i) => (
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12" id={i}>
                  <Link to={val.view_node}>
                    <div className="service service__style--2">
                      <div className="icon">
                        <i className={"fa " + val.field_icon}></i>
                      </div>
                      <div className="content">
                        <h3 className="title">{val.title}</h3>
                        <p dangerouslySetInnerHTML={{__html: val.field_short_description}}></p>
                      </div>
                    </div>
                  </Link>

                </div>
              ))}
            </div>
          </div>
        </div>
        {/* End Service Area */}


        {/* Start Service Area */}
        {specialize.length > 0 ?
          <div className="service-area we-specialize-in creative-service-wrapper pt--90 pb--120 bg_color--1">
            <div className="container">
              <div className="row service-one-wrapper">
                <div className="col-lg-12">
                  <div className="section-title text-center mb--30">
                    <h2>{specialize[0].info}</h2>
                  </div>
                </div>
              </div>
              <div className="row creative-service">
                {specialize.map((val, i) => (
                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 text-center" key={i}>
                    {val.field_url !== "" ?
                      <a href={val.field_url}>
                        <div className="service service__style--2">
                          <div className="content">
                            <h3 className="title">{val.field_label}</h3>
                          </div>
                        </div>
                      </a>
                      :
                      <div className="service service__style--2">
                        <div className="content">
                          <h3 className="title">{val.field_label}</h3>
                        </div>
                      </div>
                    }
                  </div>
                ))}
              </div>
            </div>
          </div>
          : ""}
        {/* End Service Area */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp/>
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer/>


      </React.Fragment>
    )
  }
}

export default Service;
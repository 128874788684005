import React, { Component , Fragment } from "react";
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
const constant = require('../../../config/Constant');

class CounterOne extends Component{
    constructor(props){
         super(props);
        this.state = {
            'fun_fact': []
        };
    }

    state = {
        didViewCountUp: false
    };
    onVisibilityChange = isVisible => {
        if (isVisible) {
            this.setState({didViewCountUp: true});
        }
    }

    componentDidMount(){
        fetch(constant.api.url.API_URL+'/api/fun-facts?_format=json', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then((responseJson) => {
                this.setState({
                    fun_fact: responseJson,

                });
            },
            // Note: it's important to handle errors here
            // instead of a catch() block so that we don't swallow
            // exceptions from actual bugs in components.
            (error) => {
                this.setState({
                    error
                });
            }
        ) 
    }

    render(){
        let Data = this.state.fun_fact;

        return(
            <Fragment>
            { Data.length > 0 ? 
             <div className="rn-counterup-area ptb--80 bg_image--3">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                   { this.props.title !== "" && this.props.title.info !== undefined ?
                                    <h3 className="fontWeight500"> { this.props.title.info[0].value }  </h3>
                                   : <h3 className="fontWeight500"> { this.props.title } </h3> } 
                                </div>
                            </div>
                        </div>
                    <div className="row">
                        {Data.map( (value, index) => (
                            <div className="counterup_style--1 col-lg-4 col-md-4 col-sm-6 col-12" key={index}>
                                <h5 className="counter">
                                    <VisibilitySensor onChange={this.onVisibilityChange} offset={{top:10}} delayedCall>
                                        <CountUp end={this.state.didViewCountUp ? value.field_fun_score : 0} />
                                    </VisibilitySensor>
                                </h5>
                                <p className="description" dangerouslySetInnerHTML={{ __html: value.body }}></p>
                            </div>
                        ))}
                    </div>
                 </div>
                </div>
                : "" }
            </Fragment>
        )
    }
}
export default CounterOne;
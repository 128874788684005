// React Required
import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import Favicon from 'react-favicon';

// Create Import File
import './index.scss';
import '../node_modules/font-awesome/css/font-awesome.min.css';

// Common Layout
// import Layout from "./component/common/App";


// Home layout
import Demo from './page-demo/Demo';
import MainDemo from './home/MainDemo';
import Startup from './home/Startup';
import Paralax from './home/Paralax';
import HomePortfolio from './home/HomePortfolio';
import DigitalAgency from './home/DigitalAgency';
import CreativeAgency from './home/CreativeAgency';
import PersonalPortfolio from './home/PersonalPortfolio';
import Business from './home/Business';
import StudioAgency from './home/StudioAgency';
import PortfolioLanding from './home/PortfolioLanding';
import CreativeLanding from './home/CreativeLanding';
import HomeParticles from './home/HomeParticles';
import CreativePortfolio from './home/CreativePortfolio';
import DesignerPortfolio from './home/DesignerPortfolio';

// Dark Home Layout 
import DarkMainDemo from './dark/MainDemo';
import DarkPortfolioLanding from './dark/PortfolioLanding';

// Element Layout
import Service from "./elements/Service";
import ServiceDetails from "./elements/ServiceDetails";
import About from "./elements/About";
import Contact from "./elements/Contact";
import Portfolio from "./elements/Portfolio";
import PortfolioDetails from "./elements/PortfolioDetails";
import Blog from "./elements/Blog";
import Clients from "./elements/Clients";
import BlogDetails from "./elements/BlogDetails";
import Counters from "./elements/Counters";
import error404 from "./elements/error404";
import {BrowserRouter, Switch, Route} from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
// import DocumentMeta from 'react-document-meta';

const constant = require('../config/Constant');

class Root extends Component {
  constructor(props) {
    super(props);
    this.state = {
      faviconUrl: ''
    };
  }

  componentDidMount() {

    fetch(constant.api.url.API_URL + '/api/get_basic_info', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(res => res.json())
      .then((responseJson) => {
          this.setState({
            faviconUrl: responseJson.site_favicon
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )

  }

  render() {

    let faviconUrl = "";
    if (this.state.faviconUrl !== "") {
      faviconUrl = this.state.faviconUrl;
    }

    return (

      <BrowserRouter basename={'/'}>
        <Favicon url={faviconUrl}/>
        <Switch>
          <Route exact path={`${process.env.PUBLIC_URL}/`} component={MainDemo}/>
          <Route exact path={`${process.env.PUBLIC_URL}/main-demo`} component={Demo}/>
          <Route exact path={`${process.env.PUBLIC_URL}/dark-main-demo`} component={DarkMainDemo}/>
          <Route exact path={`${process.env.PUBLIC_URL}/startup`} component={Startup}/>
          <Route exact path={`${process.env.PUBLIC_URL}/paralax`} component={Paralax}/>

          <Route exact path={`${process.env.PUBLIC_URL}/digital-agency`} component={DigitalAgency}/>
          <Route exact path={`${process.env.PUBLIC_URL}/creative-agency`} component={CreativeAgency}/>
          <Route exact path={`${process.env.PUBLIC_URL}/personal-portfolio`} component={PersonalPortfolio}/>
          <Route exact path={`${process.env.PUBLIC_URL}/studio-agency`} component={StudioAgency}/>
          <Route exact path={`${process.env.PUBLIC_URL}/business`} component={Business}/>
          <Route exact path={`${process.env.PUBLIC_URL}/portfolio-home`} component={HomePortfolio}/>
          <Route exact path={`${process.env.PUBLIC_URL}/portfolio-landing`} component={PortfolioLanding}/>
          <Route exact path={`${process.env.PUBLIC_URL}/creative-landing`} component={CreativeLanding}/>
          <Route exact path={`${process.env.PUBLIC_URL}/home-particles`} component={HomeParticles}/>
          <Route exact path={`${process.env.PUBLIC_URL}/dark-portfolio-landing`} component={DarkPortfolioLanding}/>
          <Route exact path={`${process.env.PUBLIC_URL}/designer-portfolio`} component={DesignerPortfolio}/>
          <Route exact path={`${process.env.PUBLIC_URL}/creative-portfolio`} component={CreativePortfolio}/>


          {/* Element Layot */}
          <Route exact path={`${process.env.PUBLIC_URL}/services`} component={Service}/>
          <Route exact path={`${process.env.PUBLIC_URL}/service/:id`} component={ServiceDetails}/>
          <Route exact path={`${process.env.PUBLIC_URL}/contact`} component={Contact}/>
          <Route exact path={`${process.env.PUBLIC_URL}/about`} component={About}/>
          <Route exact path={`${process.env.PUBLIC_URL}/portfolio`} component={Portfolio}/>
          <Route exact path={`${process.env.PUBLIC_URL}/portfolio/:id`} component={PortfolioDetails}/>
          <Route exact path={`${process.env.PUBLIC_URL}/resources`} component={Blog}/>
          <Route exact path={`${process.env.PUBLIC_URL}/clients`} component={Clients}/>
          <Route exact path={`${process.env.PUBLIC_URL}/resources/:id`} component={BlogDetails}/>
          <Route exact path={`${process.env.PUBLIC_URL}/counters`} component={Counters}/>


          <Route path={`${process.env.PUBLIC_URL}/404`} component={error404}/>
          <Route component={error404}/>

        </Switch>
      </BrowserRouter>

    )
  }
}

ReactDOM.render(<Root/>, document.getElementById('root'));
serviceWorker.register();
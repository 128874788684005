import React, { Component } from "react";

const constant = require('../../../config/Constant');

let ServiceList = []

class ServiceOne extends Component{
    render(){
        const { homepageService} = this.props;
        if(homepageService !== ""){
            ServiceList = homepageService;
        }
        return(
            <React.Fragment>
                { ServiceList.length > 0 ?
                <div className="row">
                    {ServiceList.map( (val , i) => (
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                            <div className="service service__style--1">
                            { val.field_image !== '' ? 
                                <div className="icon">
                                    <img src={constant.api.url.API_URL+val.field_image} alt="Digital Agency"/>
                                </div>
                            : ""}    
                                <div className="content">
                                 <h4 className="title" dangerouslySetInnerHTML={{ __html: val.field_title }}></h4>
                                 <p dangerouslySetInnerHTML={{ __html: val.field_service_description }}></p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                : "" }
            </React.Fragment>
        )
    }
}
export default ServiceOne;
import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
const constant = require('../../config/Constant');

class Testimonial extends Component{

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: true,
            testimonial: [], //BlogContent.slice(0 , 6),
        };
    }
    componentDidMount() {
          fetch(constant.api.url.API_URL+'/api/testimonials?_format=json', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(res => res.json())
            .then((responseJson) => {
                this.setState({
                    isLoaded: false,
                    testimonial: responseJson
                });
            },
            (error) => {
                this.setState({
                    isLoaded: true,
                    error
                })
         })
    }


    render(){
        let testimonials = this.state.testimonial;
        return(
            <React.Fragment>
            { testimonials.length > 0 ?  
                <div className="rn-testimonial-area bg_color--5 ptb--120">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                               <Tabs>
                                   
                                    { testimonials.map((testimonial, index) => (
                                        <TabPanel>
                                            <div className="rn-testimonial-content text-center">
                                                <div className="inner">
                                                    <p dangerouslySetInnerHTML={{__html:testimonial.body[0].value }}></p>
                                                </div>
                                                <div className="author-info">
                                                    <h6><span>{testimonial.title[0].value} </span>

                                                    { testimonial.field_company.length > 0 && testimonial.field_company[0].value !== "" ? "-" + testimonial.field_company[0].value : "" } 

                                                    </h6>
                                                </div>
                                            </div>
                                        </TabPanel>
                                    )) }
                                   
                                   


                                    <TabList className="testimonial-thumb-wrapper">
                                  
                                    { testimonials.map((testimonial, index) => (
                                        <Tab>
                                        { testimonial.field_image.length > 0 && testimonial.field_image[0].url !== "" ? 
                                            <div className="testimonial-thumbnai">
                                                <div className="thumb">
                                                    <img src={testimonial.field_image[0].url} alt="Testimonial Images"/>
                                                </div>
                                            </div>
                                         : "" }     
                                        </Tab>
                                      )) }
                                    

                                 
                                    </TabList>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
                 : '' }
            </React.Fragment>
        )
    }
}
export default Testimonial;
import React, { Component } from "react";
const constant = require('../../../config/Constant');

class ContactTwo extends Component{
    constructor(props){
        super(props);
        this.state = {
            rnName: '',
            rnEmail: '',
            rnSubject: '',
            rnMessage: '',
            submit: false,
            responseMessage:'',
            contactInfo: ''
        };
    }



    submitForm(){

        fetch(constant.api.url.API_URL+'/webform_rest/submit', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ "webform_id": "contact", "name": this.state.rnName, "email": this.state.rnEmail, "subject": this.state.rnSubject, "message": this.state.rnMessage })
        })
            .then(res => res.json())
            .then((responseJson) => {
                    if(responseJson.sid !== "" && responseJson.sid !== undefined){
                        this.setState({
                            rnName: '',
                            rnEmail: '',
                            rnSubject: '',
                            rnMessage: '',
                            submit: true,
                            responseMessage:'Form has been submitted successfully'
                        });

                    }else{
                         this.setState({
                           
                            responseMessage:'Email ID is not valid'
                        }); 
                    }
                    setTimeout(function(){
                        this.setState({
                            responseMessage:''
                        })
                    }.bind(this), 2000)
                },
                (error) => {
                    this.setState({
                        error
                    });
                }
            )
    }

    

    render(){
        return(
            <div className="contact-form--1">
                <div className="container">
                    <div className="row row--35 align-items-start">
                        <div className="col-lg-6 order-2 order-lg-1">
                            <div className="section-title text-left mb--50">
                                { this.props.contactInfo.info[0].value !== "" && this.props.contactInfo.info.length > 0}
                                <h2 className="title">{this.props.contactInfo.info[0].value}</h2>
                                { this.props.contactInfo.body.length > 0 ?
                                <div dangerouslySetInnerHTML={{ __html: this.props.contactInfo.body[0].value }}></div>
                                : ""}
                            </div>
                            <div className="form-wrapper">
                                <form>
                                    <label htmlFor="item01">
                                        <input
                                            type="text"
                                            name="name"
                                            id="item01"
                                            value={this.state.rnName}
                                            onChange={(e)=>{this.setState({rnName: e.target.value});}}
                                            placeholder="Your Name *"
                                        />
                                    </label>

                                    <label htmlFor="item02">
                                        <input
                                            type="text"
                                            name="email"
                                            id="item02"
                                            value={this.state.rnEmail}
                                            onChange={(e)=>{this.setState({rnEmail: e.target.value});}}
                                            placeholder="Your email *"
                                        />
                                    </label>

                                    <label htmlFor="item03">
                                        <input
                                            type="text"
                                            name="subject"
                                            id="item03"
                                            value={this.state.rnSubject}
                                            onChange={(e)=>{this.setState({rnSubject: e.target.value});}}
                                            placeholder="Write a Subject"
                                        />
                                    </label>
                                    <label htmlFor="item04">
                                        <textarea
                                            type="text"
                                            id="item04"
                                            name="message"
                                            value={this.state.rnMessage}
                                            onChange={(e)=>{this.setState({rnMessage: e.target.value});}}
                                            placeholder="Your Message"
                                        />
                                    </label>
                                    <button className="rn-button-style--2 btn-solid" type="button" value="submit" name="submit" onClick={()=>this.submitForm()} id="mc-embedded-subscribe">Submit</button>
                                      
                                        <div className="alert-msg">{this.state.responseMessage}</div>    
                                   
                                </form>
                            </div>
                        </div>
                        <div className="col-lg-6 order-1 order-lg-2">
                            { this.props.contactInfo.field_contact_image[0].url !== "" &&  this.props.contactInfo.field_contact_image.length > 0}
                            <div className="thumbnail mb_md--30 mb_sm--30">
                                <img src={this.props.contactInfo.field_contact_image[0].url} alt="trydo"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default ContactTwo;